import './bootstrap';
import 'particles.js';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

// Ensure particlesJS is available globally
const particlesJS = window.particlesJS;

// JSON file is located in the directory of 'public/js/particlesjs-config.json'
particlesJS.load('particles-js', '/js/particlesjs-config.json', function() {

});

// Initialize Flatpickr on the desired input fields
document.addEventListener('DOMContentLoaded', function() {
    // Single mode
    flatpickr(".datepicker-single", {
        enableTime: false,
        dateFormat: "Y-m-d", // Format to store in the input field
        altInput: true,
        altFormat: "d M Y", // Format to display to the user
        maxDate: "today",
    });

    // Range mode
    flatpickr(".datepicker-range", {
        enableTime: false,
        mode: "range",
        dateFormat: "Y-m-d", // Format to store in the input field
        altInput: true,
        altFormat: "d M Y", // Format to display to the user
    });
});